import React from "react"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"

import Layout from "@components/layout"

export default function Success() {
  
  return (
    <main>
      <Helmet>
        <html lang="en" />
        <title>FasTrack: Payment canceled</title>
        <meta name="description" content="FasTrack checkout cancelation page" />
        <meta name="robots" content="noindex"></meta>
      </Helmet>
      <Layout nobg>

        <div className="container py-8">
          <p className="text-center">
            <h1 className="mb-4 text-3xl font-bold text-center tracking-wide">Your payment was canceled</h1>
            <Link className="text-blue-500" to="/">Return to FasTrack</Link>
          </p>
        </div>

      </Layout>
      
    </main>
  )
}
